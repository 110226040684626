<template>
  <figure class="ratio-image">
    <div class="ratio-image__wrapper" :style="{ paddingTop }">
      <img :src="src" :alt="alt" class="ratio-image__elem" />
    </div>
    <figcaption class="ratio-image__caption">
      <slot />
    </figcaption>
  </figure>
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  ratio: {
    type: String,
    default: '1:1',
  },
  mobileRatio: {
    type: String,
    default: '1:1',
  },
});

const paddingTop = computed(() => {
  const ratio = typeof window !== 'undefined' && window.innerWidth < 480 ? props.mobileRatio : props.ratio;
  const [, x, y] = ratio.match(/^([0-9]+):([0-9]+)$/);
  return x && y ? `${(y / x) * 100}%` : '100%';
});
</script>

<style lang="scss" scoped>
.ratio-image {
  &__wrapper {
    position: relative;
  }

  &__elem {
    @include overlay;
    object-fit: cover;
  }

  &__caption {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.3;

    &:empty {
      display: none;
    }
  }
}
</style>
